<template>
  <div id="searchList">
    <searchV1
      class="search"
      jumpUrl="search"
      v-model="keyword"
      @search="search"
    ></searchV1>
    <div class="l-flex-g1">
      <scrollview @onRefresh="onRefresh" @onReload="onReload">
        <gridV1
          v-if="list.length > 0"
          class="box-m-t-24"
          style="height: auto"
          :col="2"
          colGap="9px"
          rowGap="10px"
        >
          <goodsV1
            v-for="(item, index) in list"
            :key="index"
            :url="item.cover"
            :title="item.title"
            :product_price="item.product_price"
            :market_price="item.market_price"
            :param="item"
            @onClick="goDetail"
          ></goodsV1>
        </gridV1>
      </scrollview>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import searchV1 from "@T/community/search/searchV1.vue";
import goodsV1 from "@T/community/goods/goodsV1.vue";
import gridV1 from "@/uicomponent/grid/gridV1.vue";

export default {
  components: {
    searchV1,
    goodsV1,
    gridV1,
  },
  data() {
    return {
      keyword: "",
      params: {
        page: 1,
        page_size: 10,
      },
      list: [],
    };
  },
  created() {
    document.title = "搜索结果";
    if (this.$route.query) {
      this.keyword = this.$route.query?.keyword || "";
    }
    this.params.page = 1;
    this.list = [];
    this.search();
  },
  methods: {
    ...mapActions("community_search", ["addHistory"]),
    onRefresh(done) {
      this.list = [];
      this.params.page = 1;
      this.search().finally(() => {
        done();
      });
    },
    /**
     * step 当前加载结束
     * over 没有更多数据了
     */
    onReload(step, over) {
      console.log("上拉加载");
      this.params.page += 1;
      this.search().finally(() => {
        if (this.list.length < this.params.page * this.params.page_size) {
          over();
        } else {
          step();
        }
      });
    },
    async search() {
      this.list = [];
      let keyword = this.$utils.string.trim(String(this.keyword));
      this.addHistory(keyword);
      try {
        let parmas = {
          ...this.params,
          keyword: keyword,
        };
        let query = await this.$api.community.search.search(parmas);
        if (query.data.length == 0) {
          return;
        }
        if (query.data.length < this.params.page_size) {
          this.list = [...this.list, ...query.data];
          return;
        }
        this.list = [...this.list, ...query.data];
      } catch (error) {
        //
      }
    },
    goDetail(e) {
      let goodsId = e.id;
      if (this.$platform.wxsdk.isWechat()) {
        let query = {
          goodsId: goodsId,
        };
        query = encodeURIComponent(JSON.stringify(query));
        this.$platform.wxsdk.wxRoute({
          type: "navigateTo",
          url: "/web/community/goods-detail?query=" + query,
        });
      } else {
        this.$router.push({
          path: "goods-detail",
          query: { goodsId: goodsId },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#searchList {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 15px 15px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
}
.search {
  width: 345px;
  height: 32px;
}
</style>
