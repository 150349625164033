<template>
  <div class="layout"
        :style="{
            'grid-template-rows': 'repeat('+row+',1fr)',
            'grid-template-columns': 'repeat('+col+',1fr)',
            'grid-row-gap':rowGap,
            'grid-col-gap': colGap
            }">
      <slot></slot>
  </div>
</template>
<script>
export default{
    props:{
        row:{
            type:Number,
            default:1,
        },
        col:{
            type:Number,
            default:5,
        },
        colGap:{
            type:String,
            default:'0px'
        },
        rowGap:{
            type:String,
            default:'10px'
        },
    }
}
</script>
<style lang="scss" scoped>
  .layout{
    width: 100%;
    height: 100%;
    display:grid;
  }
</style>